import request from '@/utils/request'

/**
 * 查询平台咨询列表
 */
export const findUsercomplaint = (data) => {
  return request({
    url: '/myUsercomplaint/findUsercomplaint',
    method: 'POST',
    data
  })
}

/**
 * 添加平台咨询列表
 */
export const addUsercomplaint = (data) => {
  return request({
    url: '/myUsercomplaint/addUsercomplaint',
    method: 'POST',
    data
  })
}

/**
 * 查询平台咨询列表
 */
export const cancelUsercomplaint = (data) => {
  return request({
    url: '/myUsercomplaint/cancelUsercomplaint',
    method: 'POST',
    data
  })
}
