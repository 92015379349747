<template>
  <div style="height: 100%">
    <!--【版头】标题 -->
    <nesi-user-common :title="`咨询管理`">
      <template #button>
        <el-button type="primary" plain @click="addTableRowDialogVisible = true"
          >新增咨询</el-button
        >
      </template>
      <!--【版头】查询条件 -->
      <template #query>
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <!--        <el-form-item label="类别">
          <el-select v-model="form.type" clearable placeholder="请选择类别">
            <el-option label="咨询" value="1"/>
            <el-option label="投诉" value="0"/>
          </el-select>
        </el-form-item>-->
          <el-form-item label="处理状态">
            <el-select
              v-model="form.handleStatus"
              clearable
              placeholder="请选择处理状态"
            >
              <el-option label="待处理" value="1" />
              <el-option label="已处理" value="2" />
              <el-option label="已撤销" value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="内容描述" style="width: 293px">
            <el-input
              v-model="form.complaintContents"
              clearable
              placeholder="请输入内容关键字"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <!--【主区】列表 -->
      <template #content>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="complaintTime" label="时间" width="160" />
          <!--        <el-table-column prop="typeName" label="类别" width="100" />-->
          <el-table-column prop="complaintContents" label="内容描述" />
          <el-table-column prop="handleStatus" label="处理状态" width="100" />
          <el-table-column prop="handleContents" label="处理回复" width="250" />
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                v-show="
                  scope.row.status === '1' && scope.row.handleContents === null
                "
                type="danger"
                size="small"
                class="list-btn"
                @click="cancelTableRow(scope.row)"
                >撤消</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="currentChange"
          :total="total"
          :page-size="form.pageSize"
          style="text-align: right; margin: 10px; 0"
        />
      </template>
    </nesi-user-common>
    <!--【弹窗】新增 -->
    <el-dialog
      title="新增平台咨询"
      width="700px"
      v-model="addTableRowDialogVisible"
    >
      <el-form :inline="true" :model="addTableRowForm" class="demo-form-inline">
        <!--      <el-form-item label="类别" label-width="150px">
        <el-select v-model="addTableRowForm.type" clearable placeholder="请选择类别" style="width:350px;" >
          <el-option label="咨询" value="1"/>
          <el-option label="投诉" value="0"/>
        </el-select>
      </el-form-item>-->
        <el-form-item label="内容描述" label-width="150px">
          <el-input
            v-model="addTableRowForm.complaintContents"
            clearable
            placeholder="请输入内容关键字"
            type="textarea"
            :rows="5"
            maxlength="255"
            style="width: 350px"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addTableRowDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addTableRow">提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ElMessageBox, ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import {
  findUsercomplaint,
  addUsercomplaint,
  cancelUsercomplaint
} from '@/api/user-complaint'
defineComponent({
  NesiUserCommon
})

/**
 * 查询
 */
const total = ref(0)
const form = reactive({
  pageNo: 1,
  pageSize: 10,
  handleStatus: '',
  type: '1',
  complaintContents: ''
})
const tableData = ref([])
const findTableList = async () => {
  const resp = await findUsercomplaint(form)
  total.value = resp.total
  tableData.value = resp.records.map((item) => {
    let handleStatus = '待处理'
    if (item.handleContents !== null) {
      handleStatus = '已处理'
    } else if (item.status === '0') {
      handleStatus = '已撤消'
    }
    const typeName = item.type === 0 ? '投诉' : '咨询'
    return {
      handleStatus: handleStatus,
      typeName: typeName,
      id: item.id,
      type: item.type,
      complaintTime: item.complaintTime,
      complaintContents: item.complaintContents,
      handleContents: item.handleContents,
      handleTime: item.handleTime,
      status: item.status
    }
  })
  console.log(resp)
}

/**
 * 添加
 */
const addTableRowDialogVisible = ref(false)
const addTableRowForm = reactive({
  type: '1',
  complaintContents: ''
})
const resetAddTableRowForm = () => {
  addTableRowForm.type = '1'
  addTableRowForm.complaintContents = ''
}
const addTableRow = async (row) => {
  const addResp = await addUsercomplaint(addTableRowForm)
  console.log(addResp)
  addTableRowDialogVisible.value = false
  ElMessage({ type: 'success', message: '添加成功' })
  resetAddTableRowForm()
  findTableList()
}

/**
 * 撤消
 */
const cancelTableRow = async (row) => {
  ElMessageBox.confirm('是否要撤消咨询投诉？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const cancelData = {
        id: row.id
      }
      const cancelResp = await cancelUsercomplaint(cancelData)
      console.log(cancelResp)
      ElMessage({ type: 'success', message: '撤消成功' })
      findTableList()
    })
    .catch(() => {})
}

/**
 * 分页
 */
const currentChange = (value) => {
  form.pageNo = value
  findTableList()
}
onMounted(() => {
  findTableList()
})
const onSubmit = () => {
  form.pageNo = 1
  findTableList()
}
</script>
<style lang="sass"></style>
